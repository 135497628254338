import { useState, useMemo, useEffect } from 'react';

const useFilter = ({ allEntries, multiOption, ref }) => {
	const [filters, setFilters] = useState({});

	const [isMobile, setIsMobile] = useState(false);
	const [filteredContentTop, setFilteredContentTop] = useState();

	const getMultiOptionEntry = (entry) => {
		if (multiOption) {
			const parsedEntry = JSON.parse(entry[multiOption]);
			if (multiOption === 'associatedCountries') {
				return { country: parsedEntry };
			}
			if (multiOption === 'companies') {
				return { company: parsedEntry };
			}
			return { [multiOption]: parsedEntry };
		}
		return null;
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			return window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
		}
	}, []);

	useEffect(() => {
		if (ref.current?.scrollLeft) {
			// eslint-disable-next-line no-param-reassign
			ref.current.scrollLeft += -10000;
		}
	}, [filters, ref]);

	useEffect(() => {
		// gets position of filter contain on page load no matter where on the page you land
		setFilteredContentTop(window.pageYOffset + ref.current.getBoundingClientRect().top);
	}, [ref]);

	// For filters that have multiple values
	const parsedEntries = allEntries.map((entry) => ({
		...entry,
		...getMultiOptionEntry(entry),
	}));

	const filteredEntries = useMemo(() => {
		const filterKeys = Object.keys(filters).filter((k) => filters[k].length);

		const filterByDataType = (entry, filterType, filtersSet) => {
			if (entry[filterType] === null) {
				return;
			}
			if (typeof entry[filterType] === 'object') {
				return filtersSet[filterType].find((element) =>
					entry[filterType].includes(element)
				);
			}
			return filtersSet[filterType].includes(entry[filterType]);
		};

		return parsedEntries.filter((entry) =>
			filterKeys.every(
				(filterType) =>
					filterByDataType(entry, filterType, filters) && entry[filterType].length > 0
			)
		);
	}, [parsedEntries, filters]);

	const changeFilter = (e) => {
		e.persist();
		const { name, value } = e.target;
		setFilters((obj) => ({
			...obj,
			...{ [name]: value.length > 0 ? [value] : value },
		}));
	};

	const updateFilter = ({ category, value }) => {
		setFilters((obj) => {
			const currentValues = obj[category] || [];
			const selected = currentValues.includes(value);

			return {
				...obj,
				[category]: selected
					? currentValues.filter((k) => k !== value)
					: [...currentValues, value],
			};
		});

		setTimeout(() => {
			window.scrollTo({
				top: filteredContentTop - 200,
				left: 0,
				behavior: 'smooth',
			});
		}, 100);
	};

	const resetFilters = () => {
		setFilters({});
	};

	return {
		updateFilter,
		changeFilter,
		filteredEntries,
		filters,
		isMobile,
		parsedEntries,
		resetFilters,
	};
};

export default useFilter;
