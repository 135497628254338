import React from 'react';
import { nanoid } from 'nanoid';
import Chevron from '../../icons/Chevron.svg';

const FilterSelect = ({ value, changeFilter, filters, filterOptions }) => {
	const filterLabel = {
		country: 'Country',
		company: 'Company',
		companyPosition: 'Position',
		services: 'Services',
	};
	return (
		<div>
			<p className="font-bold">Filter by {filterLabel[value]}:</p>
			<div className="relative">
				<select
					className="w-full px-4 py-2 my-2 bg-transparent border-2 outline-none appearance-none border-colors-legacy-form"
					name={value}
					id={value}
					value={filters[value]}
					multiple={0}
					onChange={(e) => {
						changeFilter(e);
					}}
				>
					<option value="">All</option>
					{filterOptions[value].map((e) => (
						<React.Fragment key={nanoid()}>
							{e !== '' && (
								<option key={e} value={e}>
									{e}
								</option>
							)}
						</React.Fragment>
					))}
				</select>
				<Chevron className="absolute right-3 top-[28px]" />
			</div>
		</div>
	);
};

export default FilterSelect;
