import React, { useEffect } from 'react';
import AOS from 'aos';
import CopyBlock from '../../CopyBlock';
import Phone from '../../../icons/phone.svg';
import Mail from '../../../icons/mail.svg';
import 'aos/dist/aos.css';

const ContactCard = ({ bgdImg, address, phone, email, header }) => {
	useEffect(() => {
		AOS.init({
			once: true,
		});
	}, []);
	return (
		<div
			className="flex flex-col h-full p-4 mb-2 bg-white bg-right bg-no-repeat rounded-md shadow-md bg-xsmall lg:bg-contain"
			data-aos="zoom-in"
			style={{
				backgroundImage: `url(${bgdImg})`,
			}}
		>
			<h3 className="text-xl mb-4 !font-normal">{header}</h3>
			<CopyBlock>{address}</CopyBlock>
			<div className="mt-auto ">
				<div className="flex items-center mb-2">
					<Phone width="30" alt="phone icon" />
					<a
						className="hidden ml-3 font-bold text-brand-200 sm:inline-block"
						href={`tel:${phone}`}
					>
						{phone}
					</a>
					<a
						className="inline-block ml-3 font-bold text-brand-200 sm:hidden"
						href={`tel:${phone}`}
					>
						Contact now
					</a>
				</div>
				<div className="flex items-center">
					<Mail width="30" alt="email icon" />
					<a
						className="hidden ml-3 font-bold text-brand-200 sm:inline-block"
						href={`mailto:${email}`}
					>
						{email}
					</a>
					<a
						className="inline-block ml-3 font-bold text-brand-200 sm:hidden"
						href={`mailto:${email}`}
					>
						Send email
					</a>
				</div>
			</div>
		</div>
	);
};

export default ContactCard;
