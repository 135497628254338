// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { nanoid } from 'nanoid';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import CopyBlock from '../components/CopyBlock';
import Hero from '../components/CMSBlocks/Hero';
import Phone from '../icons/phone.svg';
import useFilter from '../hooks/useFilter';
import MapPin from '../images/vectors/map-pin.svg';
import EmptyState from '../images/vectors/empty-state.svg';
import Mail from '../icons/mail.svg';
import ContactCard from '../components/CMSBlocks/ContactCard';
import Container from '../components/Container';
import MiriamPress from '../images/Miriam-Press-Office.png';
import ContactForm from '../components/ContactForm';
import FilterButtons from '../components/FilterButtons';
import Chevron from '../icons/Chevron.svg';

const getLocationByCountry = (data, country) => data.filter((el) => el.country === country);

const ContactUs = ({ data }) => {
	const [activeTab, setActiveTab] = useState(1);

	const {
		hero,
		location,
		mainAddressNode,
		mainEmail,
		mainOpeningHours,
		mainPhone,
		globalPressOffices,
		seoMetaTags,
	} = data.allDatoCmsContact.nodes[0];
	const { cardsPrimaryContactNumber, cardsPrimaryContactEmail } = data.datoCmsNews;
	const ref = useRef();

	const { updateFilter, changeFilter, filters, parsedEntries, resetFilters, filteredEntries } =
		useFilter({
			allEntries: location,
			multiOption: 'companies',
			ref,
		});
	const pathLocation = useLocation();

	const tabMap = {
		1: 'Business Enquiries',
		2: 'Investor Relations',
		3: 'Press Contacts',
	};

	const LocationCard = ({ officeData }) => (
		<div className="border-b border-secondary-400">
			<div className="flex py-4">
				<a href={officeData.googleMapsLink} target="_blank" rel="noreferrer">
					<div className="relative w-[500px] hidden lg:block lg:mr-5">
						<GatsbyImage
							image={officeData.mapImage.gatsbyImageData}
							alt={officeData.mapImage.alt || ''}
						/>
						<div className="absolute bottom-[44%] left-[47%] mx-0 ">
							<img src={officeData.pin?.companyLogo?.url || MapPin} alt="Map pin" />
						</div>
					</div>
				</a>

				<div>
					<h2 className="pb-1 text-xl font-normal text-brand-300">
						{officeData.officeName}
					</h2>
					<div className="px-2 pb-1 w-fit bg-secondary-100">
						<span className="text-xs font-light text-secondary-300">
							(
							{officeData.company.map((company, index) => (
								<span className="text-xs font-light" key={nanoid()}>
									{company}
									{index === officeData.company.length - 1
										? ''
										: officeData.company.length === 2
										? ' & '
										: ', '}
								</span>
							))}
							)
						</span>
					</div>
					<div className="mt-5">
						<p className="font-bold">Address:</p>{' '}
						<CopyBlock>{officeData.addressNode.childMdx.body}</CopyBlock>
					</div>
					<div className="mt-3">
						<p className="font-bold">Office hours:</p>
						<table>
							<tbody>
								<tr>
									<td className="w-[110px]">Monday:</td>
									<td>{officeData.openingHours.monday}</td>
								</tr>
								<tr>
									<td>Tuesday:</td>
									<td>{officeData.openingHours.tuesday}</td>
								</tr>
								<tr>
									<td>Wednesday:</td>
									<td>{officeData.openingHours.wednesday}</td>
								</tr>
								<tr>
									<td>Thursday:</td>
									<td>{officeData.openingHours.thursday}</td>
								</tr>
								<tr>
									<td>Friday:</td>
									<td>{officeData.openingHours.friday}</td>
								</tr>
								<tr>
									<td>Saturday:</td>
									<td>{officeData.openingHours.saturday}</td>
								</tr>
								<tr>
									<td>Sunday:</td>
									<td>{officeData.openingHours.sunday}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="mt-3">
						<div className="flex items-center mb-2">
							<Phone width="30" alt="phone icon" />
							<a
								className="ml-3 font-bold text-brand-200 sm:inline-block"
								href={`tel:${officeData.phoneNumber}`}
							>
								{officeData.phoneNumber}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	const renderLocations = (entries) => {
		const UKLocations = getLocationByCountry(entries, 'UK');
		const ROILocations = getLocationByCountry(entries, 'ROI');
		const CALocations = getLocationByCountry(entries, 'CA');
		const AULocations = getLocationByCountry(entries, 'AU');
		const NZLocations = getLocationByCountry(entries, 'NZ');
		return (
			<>
				{UKLocations.length > 0 && (
					<>
						<div className="py-3 text-2xl">United Kingdom</div>
						{UKLocations.map((officeData) => (
							<LocationCard officeData={officeData} key={nanoid()} />
						))}
					</>
				)}
				{ROILocations.length > 0 && (
					<>
						<div className="py-3 text-2xl">Republic of Ireland</div>
						{ROILocations.map((officeData) => (
							<LocationCard officeData={officeData} key={nanoid()} />
						))}
					</>
				)}
				{CALocations.length > 0 && (
					<>
						<div className="py-3 text-2xl">Canada</div>
						{CALocations.map((officeData) => (
							<LocationCard officeData={officeData} key={nanoid()} />
						))}
					</>
				)}
				{AULocations.length > 0 && (
					<>
						<div className="py-3 text-2xl">Australia</div>
						{AULocations.map((officeData) => (
							<LocationCard officeData={officeData} key={nanoid()} />
						))}
					</>
				)}
				{NZLocations.length > 0 && (
					<>
						<div className="py-3 text-2xl">New Zealand</div>
						{NZLocations.map((officeData) => (
							<LocationCard officeData={officeData} key={nanoid()} />
						))}
					</>
				)}
			</>
		);
	};

	const BusinessEnquires = ({ entries }) => (
		<Container>
			<div className="py-8">
				<h2 className="mb-2 text-4xl font-normal">Business Enquiries</h2>
				<p className="text-lg">
					Looking for a local Peninsula office and the services they offer? Choose the
					country from the list.
				</p>
			</div>
			<div className="relative flex flex-col-reverse xl:flex-row brighthr" ref={ref}>
				<div className="w-full xl:w-3/4">
					{entries.length > 0 ? (
						renderLocations(entries)
					) : (
						<div className="flex flex-col items-center justify-center min-h-[250px] p-8">
							<img src={EmptyState} alt="No results" />
							<p className="p-5">
								No offices for the selected filter criteria.{' '}
								<button
									type="button"
									onKeyDown={resetFilters}
									onClick={resetFilters}
									className="text-brand-200"
								>
									Clear filters
								</button>
							</p>
						</div>
					)}
				</div>
				<aside className="w-full py-0 bg-white xl:w-1/4 md:shadow-none xl:h-fit xl:px-2 xl:my-8 xl:mt-2 xl:overflow-hidden top-16 xl:top-20 ">
					<div className="z-30 flex flex-col-reverse pb-2 pointer-events-auto lg:flex-col xl:bg-transparent">
						<FilterButtons
							changeFilter={changeFilter}
							filters={filters}
							allEntries={parsedEntries}
							updateFilter={updateFilter}
							resetFilters={resetFilters}
							mediaQuerySwitch="xl"
						/>
					</div>
				</aside>
			</div>
		</Container>
	);

	const PressContacts = ({ officeData }) => (
		<>
			<Container>
				<h2 className="py-8 text-4xl font-normal">Global Press Offices</h2>
				<p className="pb-8">
					We are happy to help with any media enquiries you might have. If you are a
					member of the media, please contact one of our dedicated press offices using the
					details below.
				</p>

				<div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-2 auto-rows-fr">
					{officeData.globalPressOffices.contactCards.map((office) => (
						<div key={nanoid()} className="auto-rows-fr">
							<ContactCard
								bgdImg={office.backgroundImage.url}
								header={office.header}
								address={office.addressNode.childMdx.body}
								phone={office.phoneNumber}
								email={office.email}
							/>
						</div>
					))}
				</div>
				<div className="p-4 lg:p-8 lg:text-center">
					<p className="flex flex-wrap w-100 lg:block">
						To contact our global media relations team, please email{' '}
						<a
							className="inline-block font-bold text-brand-200"
							href="mailto:uk.pressoffice@peninsulagroupglobal.com"
						>
							uk.pressoffice@peninsulagroupglobal.com
						</a>
					</p>
					<p className="text-xs">
						Please use this email for media requests only. Due to the volume of
						enquiries, please help us by specifying your deadline.
					</p>
				</div>
			</Container>
			<div className="bg-secondary-100">
				<div className="container px-3 pt-6 pb-0 mx-auto overflow-visible">
					<div className="relative flex flex-col-reverse items-center border-white border-opacity-30 md:border-b xl:items-end xl:flex-row ">
						<div className="xl:w-1/3">
							<img
								src={MiriamPress}
								className="scale-x-[-1] md:scale-x-100"
								alt="Miriam Payne - Peninsula Press Office leader"
							/>
						</div>
						<div className="w-full my-6 xl:w-2/3">
							<h3 className="mb-3 text-3xl text-black lg:text-center md:text-left md: text-bold">
								Our press offices are led by Miriam Payne, Associate Director of
								Group PR and Comms.
							</h3>

							<div className="">
								<p className="mb-3 ">
									Miriam is an Emmy award winning journalist with over 15 years’
									experience in television news, including five years as Foreign
									News Desk Editor for NBC News. She is also a published
									children’s book author. After leaving journalism, Miriam moved
									into PR, working in both the private and third sectors before
									joining the Peninsula Group in 2021.
								</p>
								<p className="mb-3 ">
									Miriam plays a key role in positioning Peninsula Group as the
									leading authority on all things HR, employment law, health &
									safety, and employee wellbeing, as well as staying abreast of
									all industry news and developments.
								</p>
								<p className="mb-3 ">
									The teams work closely with contacts within trade, national and
									international press to provide timely, relevant, and informative
									content, securing high impact TV and radio interviews for expert
									spokespeople from the Peninsula Group.
								</p>
							</div>
							<div className="py-2 mt-auto">
								<div className="flex items-center mb-2">
									<Phone
										className="block text-black"
										width="30"
										alt="phone icon"
									/>
									<a
										className="inline-block ml-3 font-bold text-brand-200"
										href={`tel:${officeData.cardsPrimaryContactNumber}`}
									>
										{officeData.cardsPrimaryContactNumber}
									</a>
								</div>
								<div className="flex items-center">
									<Mail
										width="30"
										alt="email icon"
										className="block text-black"
									/>
									<a
										className="inline-block ml-3 font-bold text-brand-200"
										href={`mailto:${officeData.cardsPrimaryContactEmail}?subject=Request comment from Miriam Payne`}
									>
										{officeData.cardsPrimaryContactEmail}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	const InvestorRelations = ({ officeData }) => (
		<Container>
			<h2 className="py-8 text-4xl font-normal">Investor Relations</h2>
			<div className="relative flex flex-col lg:flex-row">
				<div className="w-full lg:w-3/8">
					<div className="flex items-center mb-2">
						<Phone width="30" alt="phone icon" />
						<a
							className="inline-block ml-3 font-bold text-brand-200"
							href={`tel:${officeData.mainPhone}`}
						>
							{officeData.mainPhone}
						</a>
					</div>
					<p className="py-3">
						Lines open <span className="font-bold">Monday-Friday 9am - 6pm </span>
						(excludes Bank Holidays)
					</p>
					<p>{officeData.openingHours}</p>
					<h2 className="py-3 text-xl font-normal text-brand-300">
						Manchester – Global head office
					</h2>
					<h3>Address:</h3>{' '}
					<CopyBlock>{officeData.mainAddressNode.childMdx.body}</CopyBlock>
					<div className="flex items-center mb-8">
						<Mail width="30" alt="email icon" />
						<a
							className="inline-block ml-3 font-bold text-brand-200"
							href={`mailto:${officeData.mainEmail}`}
						>
							{officeData.mainEmail}
						</a>
					</div>
				</div>
				<div className="w-full lg:w-5/8">
					<ContactForm />
				</div>
			</div>
		</Container>
	);

	const HeaderTabs = () => (
		<div className="w-full">
			<div className="absolute bottom-0">
				<div className="hidden md:flex">
					{Object.entries(tabMap).map(([tab, tabTitle]) => (
						<div
							role="button"
							onKeyDown={() => setActiveTab(tab)}
							onClick={() => setActiveTab(tab)}
							tabIndex={tab}
							key={nanoid()}
							className={`inline-block py-2 px-8 ${
								tabMap[activeTab] === tabTitle
									? 'bg-white active-tab'
									: 'bg-transparent'
							}`}
						>
							{tabTitle}
						</div>
					))}
				</div>
			</div>

			<div>
				<select
					className="block w-full px-4 py-2 bg-transparent border-2 outline-none appearance-none border-colors-legacy-form md:hidden mt-[20px] -mb-[10px]"
					name="tabs"
					id="tabs"
					onChange={(e) => {
						setActiveTab(e.target.value);
					}}
					defaultValue={activeTab}
				>
					{Object.entries(tabMap).map(([tab, tabTitle]) => (
						<option key={tab} value={tab}>
							{tabTitle}
						</option>
					))}
				</select>
				<div className="relative flex justify-end sm:hidden bottom-4 right-4">
					<Chevron />
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<DatoSEO path={pathLocation.pathname} meta={seoMetaTags} />
			<Hero
				bgImg={hero[0].backgroundImage.url}
				title={hero[0].heroTitle}
				fullWidth
				breadcrumbs={{
					currentPage: hero[0].heroTitle,
				}}
				tabs={<HeaderTabs />}
			/>
			<>
				{tabMap[activeTab] === 'Business Enquiries' && (
					<BusinessEnquires entries={filteredEntries} />
				)}
				{tabMap[activeTab] === 'Investor Relations' && (
					<InvestorRelations
						officeData={{ mainAddressNode, mainEmail, mainOpeningHours, mainPhone }}
					/>
				)}
				{tabMap[activeTab] === 'Press Contacts' && (
					<PressContacts
						officeData={{
							globalPressOffices,
							cardsPrimaryContactEmail,
							cardsPrimaryContactNumber,
						}}
					/>
				)}
			</>
		</div>
	);
};

const WrappedContactUs = (props) => (
	<DefaultLayout fullWidth>
		<ContactUs {...props} />
	</DefaultLayout>
);

export default WrappedContactUs;

export const query = graphql`
	query ContactUs {
		allDatoCmsContact {
			nodes {
				hero {
					heroTitle
					backgroundImage {
						url
					}
				}
				location {
					addressNode {
						childMdx {
							body
						}
					}
					companies
					officeName
					openingHours {
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
					}
					phoneNumber
					country
					mapImage {
						alt
						url
						gatsbyImageData(
							layout: CONSTRAINED
							width: 500
							imgixParams: { auto: "compress" }
						)
					}
					googleMapsLink
					pin {
						companyLogo {
							url
						}
					}
				}
				seoMetaTags {
					...GatsbyDatoCmsSeoMetaTags
				}
				globalPressOffices {
					contactCards {
						addressNode {
							childMdx {
								body
							}
						}
						backgroundImage {
							url
						}
						email
						phoneNumber
						header
					}
				}
				mainAddressNode {
					childMdx {
						body
					}
				}
				mainEmail
				mainOpeningHours
				mainPhone
			}
		}
		datoCmsNews {
			cardsPrimaryContactNumber
			cardsPrimaryContactEmail
		}
	}
`;
