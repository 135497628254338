// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import FilterSelect from '../FilterSelect';
import { StateIcon } from '../Header/Icons';
import Cross from '../../icons/Cross.svg';
import companiesOrder from '../../utils/constants';

const FilterButtons = ({
	className,
	changeFilter,
	filters,
	allEntries,
	updateFilter,
	resetFilters,
	mediaQuerySwitch = 'lg',
}) => {
	const [companiesShow, setCompaniesShow] = useState(true);
	const [countriesShow, setCountriesShow] = useState(true);
	const [positionShow, setPositionShow] = useState(true);
	const [servicesShow, setServicesShow] = useState(true);
	const countryName = {
		UK: 'United Kingdom',
		ROI: 'Republic of Ireland',
		CA: 'Canada',
		AU: 'Australia',
		NZ: 'New Zealand',
	};

	const countriesOrder = ['UK', 'ROI', 'CA', 'AU', 'NZ'];
	const allCountries = allEntries.flatMap((e) => e.country);
	const countries = [
		...new Set(
			allCountries.sort((a, b) => countriesOrder.indexOf(a) - countriesOrder.indexOf(b))
		),
	];

	const allCompanies = allEntries.flatMap((e) => e.company);
	const companies = [
		...new Set(
			allCompanies.sort((a, b) => companiesOrder.indexOf(a) - companiesOrder.indexOf(b))
		),
	];
	const allCompanyPositions = allEntries
		.map((e) => e.companyPosition)
		.filter((option) => option !== '');
	const companyPositions = [...new Set(allCompanyPositions)];

	const allServices = allEntries.flatMap((e) => e.services).filter((ent) => ent !== null);
	const services = [...new Set(allServices)];

	const filterOptions = {
		country: countries,
		company: companies,
		companyPosition: companyPositions,
		services,
	};

	return (
		<div className={`${className}`}>
			<div className={`${mediaQuerySwitch}:hidden`}>
				{filterOptions.services.length > 1 && (
					<FilterSelect
						value="services"
						text="Services"
						changeFilter={changeFilter}
						filters={filters}
						filterOptions={filterOptions}
					/>
				)}
				{filterOptions.country.length > 1 && (
					<FilterSelect
						value="country"
						text="Country"
						changeFilter={changeFilter}
						filters={filters}
						filterOptions={filterOptions}
					/>
				)}
				{filterOptions.company.length > 1 && (
					<FilterSelect
						value="company"
						text="Company"
						changeFilter={changeFilter}
						filters={filters}
						filterOptions={filterOptions}
					/>
				)}
				{filterOptions.companyPosition.length > 1 && (
					<FilterSelect
						value="companyPosition"
						text="Position"
						changeFilter={changeFilter}
						filters={filters}
						filterOptions={filterOptions}
					/>
				)}
			</div>

			<div className={`hidden ${mediaQuerySwitch}:block`}>
				<button
					type="button"
					className="mb-8 text-brand-200"
					onClick={() => {
						resetFilters();
					}}
				>
					<Cross className="inline-block w-6 text-brand-200" /> Clear filters
				</button>
				{filterOptions.services.length > 1 && (
					<>
						<div className="flex items-center justify-between">
							<h4 className="mb-2 text-sm font-normal md:text-base">
								Filter by service:
							</h4>
							<button type="button" onClick={() => setServicesShow(!servicesShow)}>
								<StateIcon className="w-12" open={servicesShow} />
							</button>
						</div>
						<div
							className={cn('py-2', {
								'flex flex-col': servicesShow,
								hidden: !servicesShow,
							})}
						>
							{filterOptions.services.map((option) => (
								<label
									className="flex flex-row mb-2 flex-nowrap hover:cursor-pointer"
									key={nanoid()}
								>
									<input
										type="checkbox"
										name="services"
										value={option}
										className="w-6 h-6 mr-4 border rounded-[4px] basis-6 shrink-0 border-brand-200 hover:cursor-pointer"
										onChange={() => {
											updateFilter({ category: 'services', value: option });
										}}
										checked={filters && filters.services?.includes(option)}
									/>
									<span className="text-sm font-semibold leading-tight pt-[3px]">
										{option}
									</span>
								</label>
							))}
						</div>
					</>
				)}

				{filterOptions.country.length > 1 && (
					<>
						<div className="flex items-center justify-between">
							<h4 className="mb-2 text-sm font-normal md:text-base">
								Filter by country:
							</h4>
							<button type="button" onClick={() => setCountriesShow(!countriesShow)}>
								<StateIcon className="w-12" open={countriesShow} />
							</button>
						</div>
						<div
							className={cn('py-2', {
								block: countriesShow,
								hidden: !countriesShow,
							})}
						>
							{filterOptions.country.map((option) => (
								<label
									className="flex flex-row mb-2 flex-nowrap hover:cursor-pointer"
									key={nanoid()}
								>
									<input
										type="checkbox"
										name="country"
										value={option}
										className="w-6 h-6 mr-4 border rounded-[4px] basis-6 shrink-0 border-brand-200 hover:cursor-pointer"
										onChange={() => {
											updateFilter({ category: 'country', value: option });
										}}
										checked={filters && filters.country?.includes(option)}
									/>
									<span className="text-sm font-semibold leading-tight pt-[3px]">
										{countryName[option]}
									</span>
								</label>
							))}
						</div>
					</>
				)}

				{filterOptions.company.length > 1 && (
					<>
						<div className="flex items-center justify-between">
							<h4 className="my-2 text-sm font-normal md:text-base">
								Filter by company:
							</h4>
							<button type="button" onClick={() => setCompaniesShow(!companiesShow)}>
								<StateIcon className="w-12" open={companiesShow} />
							</button>
						</div>

						<div
							className={cn('py-2', {
								block: companiesShow,
								hidden: !companiesShow,
							})}
						>
							{filterOptions.company.map((option) => (
								<label
									className="flex flex-row mb-2 flex-nowrap hover:cursor-pointer"
									key={nanoid()}
								>
									<input
										type="checkbox"
										name="company"
										value={option}
										className="w-6 h-6 mr-4 border rounded-[4px] basis-6 shrink-0 border-brand-200 hover:cursor-pointer"
										onChange={() => {
											updateFilter({ category: 'company', value: option });
										}}
										checked={filters && filters.company?.includes(option)}
									/>
									<span className="text-sm font-semibold leading-tight pt-[3px]">
										{option}
									</span>
								</label>
							))}
						</div>
					</>
				)}

				{filterOptions.companyPosition.length > 1 && (
					<>
						<div className="flex items-center justify-between">
							<h4 className="text-sm font-normal md:my-2 md:text-base ">
								Filter by position:
							</h4>
							<button type="button" onClick={() => setPositionShow(!positionShow)}>
								<StateIcon className="w-12" open={positionShow} />
							</button>
						</div>

						<div
							className={cn('py-2', {
								block: positionShow,
								hidden: !positionShow,
							})}
						>
							{filterOptions.companyPosition.map((option) => (
								<label
									className="flex flex-row mb-2 flex-nowrap hover:cursor-pointer"
									key={nanoid()}
								>
									<input
										type="checkbox"
										name="companyPosition"
										value={option}
										className="w-6 h-6 mr-4 border rounded-[4px] basis-6 shrink-0 border-brand-200 hover:cursor-pointer"
										onChange={() => {
											updateFilter({
												category: 'companyPosition',
												value: option,
											});
										}}
										checked={
											filters && filters.companyPosition?.includes(option)
										}
									/>
									<span className="text-sm font-semibold leading-tight pt-[3px]">
										{option}
									</span>
								</label>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
export default FilterButtons;
