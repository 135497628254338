export default (data, formId = 6192) =>
	new Promise((res, rej) => {
		if (!window.MktoForms2) return rej(new Error('Marketo forms is not loaded'));

		const errorTimer = setTimeout(() => rej(), 5000);

		const form = window.MktoForms2.getForm(formId);
		form.addHiddenFields({
			...data,
		});

		// Prevent Marketo redirect
		form.onSuccess(() => {
			clearTimeout(errorTimer);
			res();

			return false;
		});

		form.submit();
	});
