import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import loadScript from 'load-script2';
import submitMarketoForm from './submitMarketoForm';
import ChevronRight from '../../icons/chevron-right.svg';

const RequiredMessage = () => <p className="text-xs text-[#E40439]">Required</p>;

const SubmissionMessage = ({ status }) => {
	if (status === 'submitted') {
		return (
			<div>
				Thank you for your enquiry. A member of the team will be in touch shortly.{' '}
				<button
					type="button"
					onClick={() => window.location.reload()}
					className="inline-block pt-3 text-brand-200"
				>
					Submit another enquiry{' '}
					<span className="inline-block pl-1 font-normal text-brand-200">
						<ChevronRight />
					</span>
				</button>
			</div>
		);
	}
	if (status === 'error') {
		return <div>There was an error, please try again.</div>;
	}
};

const ContactForm = () => {
	const [firstSubmit, setFirstSubmit] = useState();

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('https://app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'https://app-lon04.marketo.com',
						'023-IMK-845',
						6192,
						(form) => {
							form.onSuccess(() => false);
						}
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const { status, isSubmitting, values, setStatus, handleSubmit, handleChange, handleBlur } =
		useFormik({
			enableReinitialize: true,
			initialValues: {
				firstandlastname: '',
				Phone: '',
				Email: '',
				leadEnquiry: '',
				LeadSource: 'Peninsula global enquiry form',
			},
			onSubmit: async (formData) => {
				submitMarketoForm(formData)
					.then(() => {
						setStatus('submitted');
					})
					.catch(() => {
						setStatus('error');
					});
			},
		});

	useEffect(() => {
		if (status) {
			setFirstSubmit(true);
		}
	}, [status]);

	return (
		<>
			<div className=" bg-secondary-100">
				<form onSubmit={handleSubmit} className={`${firstSubmit ? 'hidden' : 'p-8'}`}>
					<div className="flex flex-col max-w-full">
						<div className="mb-2 space-y-3">
							<div className="flex flex-wrap w-full md:flex-nowrap">
								<div className="w-full">
									<div className="flex items-baseline justify-between">
										<label
											htmlFor="firstandlastname"
											className="block w-full mb-2 font-bold text-left"
										>
											Full name
										</label>
										<RequiredMessage />
									</div>
									<input
										id="firstandlastname"
										name="firstandlastname"
										placeholder="Full name"
										className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
										required
										value={values.firstandlastname}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
							</div>

							<div className="flex flex-wrap w-full md:flex-nowrap">
								<div className="w-full">
									<div className="flex items-baseline justify-between">
										<label
											htmlFor="Phone"
											className="block w-full mb-2 font-bold text-left"
										>
											Phone number
										</label>
										<RequiredMessage />
									</div>
									<input
										id="Phone"
										name="Phone"
										type="tel"
										placeholder="Phone number"
										className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
										pattern="[0-9\s]{7,16}"
										title="Enter a valid telephone number between 7 and 16 digits."
										required
										value={values.Phone}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
							</div>
							<div className="flex flex-wrap w-full md:flex-nowrap">
								<div className="w-full">
									<div className="flex items-baseline justify-between">
										<label
											htmlFor="ContactEmail"
											className="block w-full mb-2 font-bold text-left"
										>
											Email
										</label>
										<RequiredMessage />
									</div>
									<input
										id="ContactEmail"
										name="Email"
										placeholder="Email"
										className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
										type="email"
										required
										value={values.Email}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
							</div>

							<div className="flex flex-wrap w-full md:flex-nowrap">
								<div className="w-full">
									<div className="flex items-baseline justify-between">
										<label
											htmlFor="leadEnquiry"
											className="block w-full mb-2 font-bold text-left"
										>
											Please type your enquiry
										</label>
										<RequiredMessage />
									</div>
									<textarea
										placeholder="Please type your enquiry"
										id="leadEnquiry"
										name="leadEnquiry"
										required
										className="w-full h-32 px-3 py-2 border-2 border-gray-700 rounded-md"
										value={values.leadEnquiry}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
							</div>
							<p className="text-xs">
								Peninsula Group Global will use your details to get in touch using
								the details provided. Any data submitted will be handled according
								to our{' '}
								<a
									href="https://www.peninsulagrouplimited.com/privacy-policy/"
									target="_blank"
									rel="noreferrer"
									className="text-xs text-brand-200"
								>
									privacy policy
								</a>{' '}
								and terms and conditions.
							</p>

							<div className="flex flex-row flex-wrap items-center mt-2">
								<button
									type="submit"
									disabled={isSubmitting}
									className="bg-white border-2 border-brand-200 rounded-[50px] text-brand-200 px-4 py-2 text-sm mt-4"
								>
									Submit
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			{firstSubmit && (
				<div className="p-8 bg-secondary-100">
					<div>
						<SubmissionMessage status={status} setStatus={setStatus} />
					</div>
				</div>
			)}
		</>
	);
};

export default ContactForm;
